import React, { CSSProperties } from 'react';
import clsx from 'clsx';

interface IProps {
	className?: string;
	children?: React.ReactNode | string;
	disabled?: boolean;
	onClick?: () => void;
	style?: CSSProperties;
}

function Button(props: IProps) {
	const { children, className, disabled, ...rest } = props;
	return (
		<button className={clsx(className)} disabled={disabled} {...rest}>
			{children}
		</button>
	);
}

export default Button;
