import { createSelector } from 'reselect';
import { IInstruction } from '../../interfaces/products';
import { RootState } from '../rootReducer';

// eslint-disable-next-line no-shadow
const state = ({ product }: RootState) => product;
export const errorSelector = createSelector(state, ({ error }) => error);
export const productSelector = createSelector(state, ({ product }) => product);
export const instructionsSelector = createSelector(state, ({ product }) => {
	const instructions = product?.instructions?.map(({ id, steps, title }: IInstruction) => {
		return {
			id,
			steps,
			title,
		};
	});
	return instructions || [];
});
export const indexInstructionSelector = createSelector(state, ({ indexInstruction }) => indexInstruction);
export const instructionSelector = createSelector(state, ({ indexInstruction }) => indexInstruction);
export const currentIndexStepSelector = createSelector(state, ({ currentStep }) => currentStep);
export const currentStepSelector = createSelector(
	state,
	indexInstructionSelector,
	currentIndexStepSelector,
	instructionsSelector,
	({ currentStep }, currentInstruction, currentIndexStep, instructions) => {
		return instructions[currentInstruction]?.steps[currentIndexStep] || null;
	},
);
