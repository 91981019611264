import { useDispatch, useSelector } from 'react-redux';
import { indexInstructionSelector, productSelector } from '../../store/selector/productSelector';
import { cancelDialogAction, setDialogAction } from '../../store/actions/dialog';
import { Button } from '../../components';
import ShareModalContent from '../ShareModalContent';
import FeedbackModalContent from '../FeedbackModalContent';
import { IDialogPayload } from '../../interfaces/dialog';
import useResponsive from '../../hooks/useResponsive';
import './Header.scss';

function Header() {
	const dispatch = useDispatch();
	const product = useSelector(productSelector);
	const currentInstructionIndex = useSelector(indexInstructionSelector);
	const { isMobile } = useResponsive();

	function showModalHandler({ content, titleText, activeClass }: IDialogPayload) {
		dispatch(setDialogAction({ content, titleText, activeClass }));
	}

	function exitFullScreen() {
		dispatch(cancelDialogAction());
		if (document.fullscreenElement) {
			document.exitFullscreen()?.then(() => console.log('Document Exited from Full screen mode'));
		}
		window.parent?.postMessage('close-fastep-fullscreen', '*');
	}

	return (
		<header className="header">
			{!!product &&
				(() => {
					const { category, image, brand, title, instructions } = product;
					return (
						<>
							<div className="bread-crumbs">
								<img src={image?.url} className="preview" alt="Стиральная машина Candy AG-DF 145698" />
								<h2>
									{category?.title} {brand?.title} {title}
									<span className="title only-desktop">
										{instructions?.length ? instructions[currentInstructionIndex].title : ''}
									</span>
									<span className="title only-mobile">Инструкции</span>
								</h2>
							</div>
							<div className="btns-group">
								{!isMobile && (
									<Button
										className="btn-modal btn-white"
										onClick={() =>
											showModalHandler({
												content: <FeedbackModalContent />,
												titleText: 'Помогла ли вам инструкция?',
												activeClass: 'modal-close-vidget',
											})
										}
									>
										Оставить отзыв
									</Button>
								)}
								<Button
									className="btn-share"
									onClick={() =>
										showModalHandler({
											content: <ShareModalContent />,
											titleText: 'Поделиться',
											activeClass: 'modal-share',
										})
									}
								/>
								{/* eslint-disable-next-line react/jsx-no-bind */}
								<Button className="btn-close" onClick={exitFullScreen} />
							</div>
						</>
					);
				})()}
		</header>
	);
}

export default Header;
