import React from 'react';
import copySvg from '../../assets/img/btn_link.svg';

function ShareModalContent() {
	return (
		<>
			<div className="link-title">Ссылка на инструкцию</div>
			<div className="link-wrapper">
				<input
					type="text"
					name="link"
					className="url-input"
					value={window.location !== window.parent.location ? document.referrer : document.location.href}
				/>
			</div>
			<button
				className="btn-modal btn-link"
				onClick={() => {
					const inputUrl = document.querySelector('.url-input');
					// @ts-ignore
					inputUrl?.select();
					document.execCommand('copy');
				}}
			>
				<img src={copySvg} alt="" />
				Копировать ссылку
			</button>
		</>
	);
}

export default ShareModalContent;
