import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	instructionsSelector,
	indexInstructionSelector,
	currentIndexStepSelector,
	currentStepSelector,
} from '../../store/selector/productSelector';
import { setIsOpenSidebarAction } from '../../store/actions/sidebar';
import { sideBarIsOpenSelector } from '../../store/selector/sidebarSelectors';
import { scaleSelector, stepScaleSelector } from '../../store/selector/scaleSelector';
import { Loader, Marker } from '../index';
import { getInitImgSizes } from '../../utils/helper/getInitImgSizes';
import useDraggableScroll from '../../hooks/useDraggebleScroll';
import useResponsive from '../../hooks/useResponsive';
import { setScaleAction } from '../../store/actions/scale';

function Content() {
	const dispatch = useDispatch();

	const currentIndexStep = useSelector(currentIndexStepSelector);
	const currentStep = useSelector(currentStepSelector);
	const currentIndexInstruction = useSelector(indexInstructionSelector);
	const instructions = useSelector(instructionsSelector);
	const sidebarIsOpen = useSelector(sideBarIsOpenSelector);
	const scale = useSelector(scaleSelector);
	const stepScale = useSelector(stepScaleSelector);

	const imgRef = useRef<HTMLImageElement | null>(null);
	const fakeImgRef = useRef<HTMLImageElement | null>(null);
	const wrapperElRef = useRef<HTMLDivElement | null>(null);
	const mainElRef = useRef(null);
	const imgWrapperElRef = useRef<HTMLDivElement | null>(null);

	const [isLoadingImg, setIsLoadingImg] = useState<boolean>(true);
	const [isLoadedImage, setIsLoadedImage] = useState(false);
	const [imgInitSizes, setImgInitSizes] = useState<{ width: number; height: number } | null>(null);
	const { onMouseDown } = useDraggableScroll(wrapperElRef);
	const { isMobile } = useResponsive();

	const imgUrl = currentStep.button?.marker?.marker || '';

	const positionMarkerFromApi = currentStep.button?.position || {
		x: 0,
		y: 0,
	};
	const sizeMarkerFromApi = currentStep.button?.size || {
		x: 0,
		y: 0,
	};

	const colorMarkerFromApi =
		instructions[currentIndexInstruction]?.steps[currentIndexStep]?.button?.color || 'orange';

	useEffect(() => {
		dispatch(setScaleAction(1));
		setIsLoadedImage(false);
		setIsLoadingImg(true);
	}, [imgUrl]);

	useEffect(() => {
		if (isLoadingImg) return;
		if (currentIndexStep !== 0 && !currentIndexStep) return;
		if (!imgRef.current) return;
		if (!fakeImgRef.current) return;
		if (isLoadedImage) {
			setTimeout(() => {
				setImgInitSizes(
					getInitImgSizes({
						imgRef: fakeImgRef,
						wrapperRef: wrapperElRef,
						stepScale,
					}),
				);
			});
		}
	}, [isLoadedImage]);

	function loadImageHandler() {
		setTimeout(() => {
			setIsLoadingImg(false);
			setIsLoadedImage(true);
		});
	}

	return (
		<>
			<div className="content" ref={mainElRef}>
				{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
				<div
					className="wrapper"
					ref={wrapperElRef}
					onMouseDown={(e) => onMouseDown(e)}
					onMouseMove={(e) => e.preventDefault()}
				>
					<div className="inner">
						<div
							ref={imgWrapperElRef}
							style={{
								position: 'relative',
							}}
						>
							<img
								src={imgUrl}
								ref={imgRef}
								alt=""
								style={
									imgInitSizes
										? { width: imgInitSizes.width * scale, height: imgInitSizes.height * scale }
										: undefined
								}
								onLoad={loadImageHandler}
								className="picture"
							/>
							{(() => {
								switch (true) {
									case isLoadedImage && !isLoadingImg:
										return (
											<Marker
												key={`${sidebarIsOpen}-${isLoadingImg}-${currentIndexStep}`}
												colorMarkerFromApi={colorMarkerFromApi}
												positionMarkerFromApi={positionMarkerFromApi}
												sizeMarkerFromApi={sizeMarkerFromApi}
												imgWrapper={imgWrapperElRef}
												imgRef={imgRef}
												scale={scale}
											/>
										);
									case !isLoadedImage && isLoadingImg:
										return <Loader />;
									case !isLoadedImage:
										return <Loader />;
									default:
										return null;
								}
							})()}
						</div>
					</div>
				</div>
				<div
					onClick={() => {
						dispatch(setIsOpenSidebarAction(false));
					}}
					tabIndex={-1}
					role={'button'}
					className={`black-overlay ${sidebarIsOpen ? 'black-overlay-visible' : ''}`}
				/>
			</div>

			<img
				src={imgUrl}
				ref={fakeImgRef}
				style={{ position: 'absolute', zIndex: -99, left: -99999, top: -9999 }}
				alt=""
				onLoad={loadImageHandler}
			/>
		</>
	);
}
export default Content;
