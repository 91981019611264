import { combineReducers } from 'redux';
import { dialogReducer } from './redusers/dialogReducer';
import { productReducer } from './redusers/productReducer';
import { sidebarReducer } from './redusers/sidebarReducer';
import { scaleReducer } from './redusers/scaleReducer';

const rootReducer = combineReducers({
	product: productReducer,
	dialog: dialogReducer,
	sidebar: sidebarReducer,
	scale: scaleReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
