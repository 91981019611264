import { useDispatch, useSelector } from 'react-redux';
import { setIndexInstructionAction, setStepAction } from '../../store/actions/product';
import { instructionsSelector, indexInstructionSelector } from '../../store/selector/productSelector';
import AccordionItem from './AccordionItem/AccordionItem';
import { sideBarIsOpenSelector } from '../../store/selector/sidebarSelectors';
import { setIsOpenSidebarAction } from '../../store/actions/sidebar';
import useResponsive from '../../hooks/useResponsive';

export default function Index() {
	const dispatch = useDispatch();

	const instructions = useSelector(instructionsSelector);
	const indexInstruction = useSelector(indexInstructionSelector);
	const sideBarIsOpen = useSelector(sideBarIsOpenSelector);

	const { isMobile } = useResponsive();

	const setInstruction = (i: number) => {
		if (indexInstruction !== i) {
			dispatch(setStepAction(0));
			dispatch(setIndexInstructionAction(i));
			isMobile && dispatch(setIsOpenSidebarAction(!sideBarIsOpen));
		}
	};

	return (
		<ul className="accordion">
			{instructions.map(({ id, title, steps }, index) => {
				return (
					<AccordionItem
						key={id}
						title={title}
						steps={steps}
						activeItem={index === indexInstruction}
						setInstruction={() => setInstruction(index)}
					/>
				);
			})}
		</ul>
	);
}
