/* eslint-disable no-shadow */

export const enum EDialogActions {
	SetDialog = 'SET_DIALOG',
	CancelDialog = 'CANCEL_DIALOG',
}

export const enum EProductActions {
	fetchProduct = 'FETCH_PRODUCT',
	setProductError = 'SET_PRODUCT_ERROR',
}

export const enum EAccordionActions {
	setStep = 'SET_STEP',
	setIndexInstruction = 'SET_INDEX_INSTRUCTION',
	setInstruction = 'SET_INSTRUCTION',
}

export const enum ESidebarActions {
	setIsOpen = 'SET_IS_OPEN',
}

export const enum EScaleActions {
	setScale = 'SET_SCALE',
	setStepScale = 'SET_STEP_SCALE',
}
