import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sideBarIsOpenSelector } from '../../store/selector/sidebarSelectors';
import { Accordion, Button } from '../../components';
import ShareModalContent from '../ShareModalContent';
import { setDialogAction } from '../../store/actions/dialog';
import useResponsive from '../../hooks/useResponsive';
import FeedbackModalContent from '../FeedbackModalContent';
import { setIsOpenSidebarAction } from '../../store/actions/sidebar';
import './Sidebar.scss';

function Sidebar() {
	const dispatch = useDispatch();
	const isOpen = useSelector(sideBarIsOpenSelector);
	const { isMobile } = useResponsive();

	useEffect(() => {
		dispatch(setIsOpenSidebarAction(true));
	}, []);

	return (
		<div className="sidebar-wrapper">
			<Button
				className={`btn-sidebar ${isOpen ? 'btn-sidebar-expanded' : ''}`}
				onClick={() => {
					dispatch(setIsOpenSidebarAction(!isOpen));
				}}
			/>
			<aside className={`scrollbar ${isOpen ? 'sidebar-expanded' : ''} sidebar`}>
				<nav className="navigation">
					<h2 className="only-desktop">Инструкции</h2>
					<div className="btns-header">
						{isMobile && (
							<>
								<Button
									className="btn-modal btn-white"
									onClick={() =>
										dispatch(
											setDialogAction({
												content: <FeedbackModalContent />,
												titleText: 'Помогла ли вам инструкция?',
												activeClass: 'modal-close-vidget',
											}),
										)
									}
								>
									Оставить отзыв
								</Button>
								<Button
									className="btn-share"
									onClick={() =>
										dispatch(
											setDialogAction({
												content: <ShareModalContent />,
												titleText: 'Поделиться',
												activeClass: 'modal-share',
											}),
										)
									}
								/>
							</>
						)}
					</div>
					<h2 className="only-mobile">Выберите инструкцию</h2>
					<Accordion />
				</nav>
			</aside>
		</div>
	);
}

export default Sidebar;
