import { IProductsState, TProductActions } from '../../interfaces/products';
import { EAccordionActions, EProductActions } from '../../enums/actions.enum';

const initialState: IProductsState = {
	product: null,
	error: null,
	indexInstruction: 0,
	currentStep: 0,
};

// eslint-disable-next-line default-param-last
export const productReducer = (state = initialState, action: TProductActions): IProductsState => {
	switch (action.type) {
		case EProductActions.fetchProduct: {
			const { instructions } = action.payload;
			const sortInstructions = instructions!
				.sort((a, b) => a.weight - b.weight)
				.map((instruction) => {
					const sortSteps = instruction.steps.sort((a, b) => a.order - b.order);
					return {
						...instruction,
						steps: sortSteps,
					};
				});
			return {
				...state,
				error: null,
				product: {
					...action.payload,
					instructions: sortInstructions,
				},
			};
		}
		case EProductActions.setProductError: {
			return {
				...state,
				error: action.payload,
			};
		}
		case EAccordionActions.setStep: {
			return {
				...state,
				currentStep: action.payload,
			};
		}
		case EAccordionActions.setIndexInstruction: {
			return {
				...state,
				indexInstruction: action.payload,
			};
		}
		default:
			return { ...state };
	}
};
