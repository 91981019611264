import { RefObject } from 'react';

interface IParams {
	imgRef: RefObject<HTMLImageElement>;
	wrapperRef: RefObject<HTMLDivElement>;
	stepScale: number;
}

export function getInitImgSizes({ imgRef, wrapperRef, stepScale }: IParams): { width: number; height: number } | null {
	if (!imgRef.current || !wrapperRef.current || window.innerWidth < 320) return null;

	const imgElem = imgRef.current;
	const wrapperEl = wrapperRef.current;

	const wrapperHeight = wrapperEl.offsetHeight;
	const wrapperWidth = wrapperEl.offsetWidth;

	const imgHeightInit = imgElem.offsetHeight;
	const imgWidthInit = imgElem.offsetWidth;

	let imgHeight = imgHeightInit;
	let imgWidth = imgWidthInit;

	if (wrapperHeight < imgHeight || wrapperWidth < imgWidth) {
		// eslint-disable-next-line no-constant-condition
		while (true) {
			imgHeight -= imgHeightInit - (imgHeightInit - imgHeightInit * stepScale);
			imgWidth -= imgWidthInit - (imgWidthInit - imgWidthInit * stepScale);
			if (wrapperHeight > imgHeight) {
				if (wrapperWidth > imgWidth) {
					break;
				}
			}
		}
	}

	return {
		width: imgWidth,
		height: imgHeight,
	};
}
