import React from 'react';
import { useSelector } from 'react-redux';
import { Header, Sidebar, Backdrop, Dialog, Loader } from '../components';
import { productSelector } from '../store/selector/productSelector';
import Footer from '../modules/Footer';

interface IProps {
	children?: React.ReactNode;
}

function Layout({ children }: IProps) {
	const product = useSelector(productSelector);

	return (
		<>
			{!product ? (
				<div className="main-loader-wrapper">
					<Loader />
				</div>
			) : (
				<main>
					<Header />
					<Sidebar />
					{children}
					<Footer />
					<Backdrop />
				</main>
			)}
			<Dialog />
		</>
	);
}

export default Layout;
