import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../Button';
import { dialogSelector } from '../../store/selector/dialogSelector';
import { errorSelector } from '../../store/selector/productSelector';
import { cancelDialogAction } from '../../store/actions/dialog';
import './Dialog.scss';

function Modal() {
	const dispatch = useDispatch();
	const error = useSelector(errorSelector);
	const { onClose, titleText, visible, content, activeClass } = useSelector(dialogSelector);
	const handleClose = () => {
		onClose && onClose();
		dispatch(cancelDialogAction());
	};

	return visible ? (
		<div className={`modal ${activeClass}`}>
			<Button className="btn-close-modal" onClick={handleClose} />
			<h2>{titleText}</h2>
			{content || <h2 style={{ textAlign: 'center' }}>{error}</h2>}
		</div>
	) : null;
}

export default memo(Modal);
