import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { currentIndexStepSelector } from '../../store/selector/productSelector';
import { sideBarIsOpenSelector } from '../../store/selector/sidebarSelectors';
import { IPosition, ISize } from '../../interfaces/products';

interface IProps {
	imgWrapper: React.RefObject<HTMLDivElement>;
	colorMarkerFromApi: string;
	imgRef: React.RefObject<HTMLImageElement>;
	sizeMarkerFromApi: ISize;
	positionMarkerFromApi: IPosition;
	scale: number;
}

let timeOutId: ReturnType<typeof setTimeout>;

function Marker({ imgWrapper, positionMarkerFromApi, sizeMarkerFromApi, colorMarkerFromApi, imgRef, scale }: IProps) {
	const currentIndexStep = useSelector(currentIndexStepSelector);
	const isOpenSidebar = useSelector(sideBarIsOpenSelector);
	const [markerState, setMarkerState] = useState<{
		position: IPosition;
		size: ISize;
	}>({
		position: {
			x: 0,
			y: 0,
		},
		size: {
			x: 0,
			y: 0,
		},
	});

	const markerRef = useRef<HTMLDivElement | null>(null);

	const calculationMarker = useCallback(() => {
		const imgElem = imgRef.current;
		const wrapperElem = imgWrapper.current;
		if (!imgElem || !wrapperElem) return;
		const { offsetHeight, offsetWidth } = wrapperElem;
		const { naturalHeight, naturalWidth } = imgElem;
		setMarkerState({
			position: {
				y: (positionMarkerFromApi.y / offsetHeight) * 100 * (offsetHeight / naturalHeight),
				x: (positionMarkerFromApi.x / offsetWidth) * 100 * (offsetWidth / naturalWidth),
			},
			size: {
				x: (sizeMarkerFromApi.x / offsetWidth) * 100 * (offsetWidth / naturalWidth),
				y: (sizeMarkerFromApi.y / offsetHeight) * 100 * (offsetHeight / naturalHeight),
			},
		});
	}, [isOpenSidebar, markerState, currentIndexStep]);

	const scrollToCenter = useCallback(
		(isTimeout?: boolean) => {
			if (!markerRef?.current) return;
			const markerElem = markerRef.current;
			if (isTimeout) {
				setTimeout(() => {
					markerElem.scrollIntoView({
						block: 'center',
						inline: 'center',
						behavior: 'smooth',
					});
				}, 0);
			} else {
				markerElem.scrollIntoView({
					block: 'center',
					inline: 'center',
					behavior: 'smooth',
				});
			}
		},
		[markerState],
	);

	useEffect(() => {
		window.onresize = () => calculationMarker();
		imgRef.current?.addEventListener('load', calculationMarker);
		return () => {
			window.removeEventListener('resize', calculationMarker);
			imgRef.current?.removeEventListener('load', calculationMarker);
		};
	}, []);

	useEffect(() => {
		calculationMarker();
		scrollToCenter(true);
	}, [currentIndexStep]);

	useEffect(() => {
		scrollToCenter();
	}, [scale]);

	useEffect(() => {
		scrollToCenter();
	}, [markerState]);

	const { size, position } = markerState;

	return (
		<div
			ref={markerRef}
			style={{
				position: 'absolute',
				border: `2px dashed ${colorMarkerFromApi}`,
				width: `${size.x}%`,
				height: `${size.y}%`,
				left: `${position.x}%`,
				top: `${position.y}%`,
			}}
		/>
	);
}

export default Marker;
