import React, { memo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AxiosError } from 'axios';
import { setDialogAction } from './store/actions/dialog';
import { getProductThunk } from './store/actions/product';
import Layout from './layout';
import { Content } from './components';
import getQueryToObject from './utils/helper/getQueryToObject';
import { getErrorString } from './utils/helper/getErrorString';

function App() {
	const dispatch = useDispatch();
	const location = useLocation();

	useEffect(() => {
		async function handle() {
			try {
				const productId = getQueryToObject(location.search)?.productId;
				if (!productId) {
					throw Error('Не productId!');
				}
				await dispatch(getProductThunk(productId));
			} catch (err) {
				dispatch(
					setDialogAction({ titleText: 'Произошла ошибка!', content: getErrorString(err as AxiosError) }),
				);
			}
		}
		handle();
	}, []);

	return (
		<Layout>
			<Content />
		</Layout>
	);
}

export default memo(App);
