import { useDispatch, useSelector } from 'react-redux';
import { IStep } from '../../../interfaces/products';
import { setStepAction } from '../../../store/actions/product';
import { currentIndexStepSelector } from '../../../store/selector/productSelector';

interface IProps {
	title: string;
	steps: IStep[] | null;
	activeItem: boolean;
	setInstruction: () => void;
}

export default function AccordionItem({ title, steps, activeItem, setInstruction }: IProps) {
	const dispatch = useDispatch();
	const currentStep = useSelector(currentIndexStepSelector);
	const setStep = (i: number) => {
		if (currentStep !== i) {
			dispatch(setStepAction(i));
		}
	};

	return (
		<li className="accordion-item" onClick={setInstruction}>
			<div className={`accordion-title ${activeItem ? 'accordion-active' : ''}`}>{title}</div>
			<ol className="accordion-content" style={{ display: activeItem ? 'block' : 'none' }}>
				{steps?.map(({ id, title: stepTitle }, index) => {
					return (
						<li
							key={id}
							className={index === currentStep ? 'step-select' : ''}
							onClick={() => setStep(index)}
						>
							{stepTitle}
						</li>
					);
				})}
			</ol>
		</li>
	);
}
